export const UserRole = {
  Admin: 0,
  Editor: 1,
};
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'pt';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'pt', name: 'Português - LTR', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];
export const currentUser = {
  id: null,
  title: null,
  img: null,
  date: null,
  role: null,
};

/*
export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};
*/

export const adminRoot = '/app';
export const leiloes = '/leiloes';
export const home = '/';
export const cadastrese = '/cadastro';
export const user = '/user';
export const searchPath = `${adminRoot}/search`;
export const buyUrl = 'https://federal.lel.br/';
export const servicePath = process.env.REACT_APP_API_URL;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
// export const defaultColor = 'light.purplemonster';
export const defaultColor = 'light.bluenavy';
// export const defaultColorAssociado = 'light.blueyale';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
// export const colors = ['bluenavy'];

export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
