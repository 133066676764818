// import { AuthProvider } from '../hooks/auth';
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  servicePath,
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentRadius -> error', error);
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentRadius -> error', error);
  }
};

export const getCurrentLanguage = () => {
  let language;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage')).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentLanguage -> error', error);
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentLanguage -> error', error);
  }
};

export const getCurrentUser = () => {
  let user;
  try {
    user =
      localStorage.getItem('federal_user') != null
        ? JSON.parse(localStorage.getItem('federal_user')) // gogo_current_user
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    // console.log('aqui', user);
    if (user) {
      localStorage.setItem('federal_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('federal_user'); // gogo_current_user
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getDateWithFormatPt = (date, type) => {
  const today = new Date(date);
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!
  let hh = today.getHours();
  let min = today.getMinutes();
  let ss = today.getSeconds();
  let yyyy = today.getFullYear();

  if (type === 'UTC') {
    yyyy = today.getUTCFullYear();
    dd = today.getUTCDate();
    mm = today.getUTCMonth() + 1;
    hh = today.getUTCHours();
    min = today.getUTCMinutes();
    ss = today.getUTCSeconds();

    // formatDate(getUTCDate(), 'DD MMM, YYYY HH:mm:ss');
  }

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (min < 10) {
    min = `0${min}`;
  }
  if (ss < 10) {
    ss = `0${ss}`;
  }
  return `${dd}/${mm}/${yyyy} ${hh}:${min}:${ss}`;
};

// função para ver se o Token é valido.
// async function checkAuth() {

export const checkAuth = async () => {
  const currentUser = getCurrentUser();
  let token = null;
  if (currentUser) {
    token = currentUser.uid;
    const apiUrLogin = `${servicePath}/login/tocheckLogged`;
    const checklogado = await axios
      .get(`${apiUrLogin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        },
      })
      .then((response) => {
        // console.log('este', response.data);
        if (response.data === 'erro') {
          return false;
        }
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    return checklogado;
  }
  return false;
};

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const AuthVerify = () => {
  const currentUser = getCurrentUser();
  let token = null;
  if (currentUser) {
    token = currentUser.uid;
  }

  const decodedJwt = parseJwt(token);

  if (decodedJwt === null) {
    return true;
  }

  if (decodedJwt.exp * 1000 < Date.now()) {
    return false;
  }

  return true;
};

export const updateUser = async (data) => {
  const currentUser = getCurrentUser();
  let token = null;
  if (currentUser) {
    token = currentUser.uid;
  } else {
    return false;
  }

  const item = {
    id: data.id,
    avatar: data.avatar,
    img: data.avatar_url,
    uid: token,
    apelido: data.apelido,
    name: data.name,
    namefull: data.namecomplete,
    cidade: data.cidade,
    estado: data.estado,
    date: data.data,
    role: 1,
  };

  setCurrentUser(item);

  return true;
};

export const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const dateNow = Date.now();

export function currencyFormatter(value) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
}

// Formata Moeda para valor americano
export const valormonetarioamericano = (valor) => {
  const valorfinal = parseFloat(
    String(valor)
      .replace(/[^0-9,]*/g, '')
      .replace(',', '.')
  ).toFixed(2);
  return valorfinal;
};
